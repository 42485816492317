/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'
import Theme from './shared/colors'

const GlobalStyle = createGlobalStyle`
    html {
        scroll-behavior: smooth;
    }
    body {
        margin: 0;
        padding: 0;
        background-color: ${Theme.ui.primary};
        color: ${Theme.text.primary};
        overflow-x: hidden;

        * {
            box-sizing: border-box;
        }
    }
`

const Layout = ({ children }) => {
    return (
        <>
            <GlobalStyle />
            {children}
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
