const colors = {
    smalt: '#003594',
    venetianRed: '#BA0C2F',
    greyNurse: 'rgb(200,201,199,.3)',
    greyChateau: '#97999B',
    gunPowder: '#4B4B4C',
    white: 'rgba(255,255,255,1)',
}

const Theme = {
    brand: {
        primary: `${colors.smalt}`,
    },
    ui: {
        primary: `${colors.white}`,
        secondary: `${colors.greyChateau}`,
        tertiary: `${colors.greyNurse}`
    },
    text: {
        primary: `${colors.gunPowder}`,
        heading: `${colors.smalt}`,
        link: `${colors.smalt}`,
        hover: `${colors.venetianRed}`,
        inverse: `${colors.white}`,
    },
    field: {
        background: `${colors.white}`,
        text: `${colors.greyChateau}`,
    },
    support: `${colors.white}`,
}

export default Theme